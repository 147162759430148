@media print {
  .print_btn {
    display: none;
  }
  .start {
    background-color: green !important;
  }
}
@media print {
  /* Ensure all necessary styles are included */
  table {
    border-collapse: collapse;
    width: 100%;
  }
  th,
  td {
    border: 1px solid black;
    padding: 8px;
    text-align: left;
  }
  body {
    margin: 0;
    padding: 0;
    font-size: 12pt; /* Adjust as needed */
  }
  .no-print {
    display: none;
  }
}
